<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Configurar sección {{ section.name }}</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="7" sm="7"> </v-col>
            <v-col cols="12" md="5" sm="5">
              <v-row justify="end">
                <v-btn
                  color="primary"
                  class="ma-2 white--text mr-5"
                  @click="
                    modalSort = true;
                    randomKey = Math.random();
                  "
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                  Ordenar
                </v-btn>

                <v-btn
                  color="primary"
                  class="ma-2 white--text"
                  @click="newItem"
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                  Agregar item
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="localSection.target"
            :search="search"
            :items-per-page="5"
            fixed-header
            :loading="loading"
            :sort-by="['position']"
            class="mt-5"
            :sort-desc="[false, true]"
            item-key="id"
            :footer-props="{
              itemsPerPageOptions: [5],
            }"
          >
            <!-- slot active -->
            <template v-slot:[`item.active`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="switchControlChanged({ active: item.active }, item)"
                  v-model="item.active"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.isBusinessActive`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="
                    switchControlChanged(
                      { isBusinessActive: item.isBusinessActive },
                      item
                    )
                  "
                  v-model="item.isBusinessActive"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.isComingSoon`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="
                    switchControlChanged(
                      { isComingSoon: item.isComingSoon },
                      item
                    )
                  "
                  v-model="item.isComingSoon"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.isOpen`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="switchControlChanged({ isOpen: item.isOpen }, item)"
                  v-model="item.isOpen"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.closed`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="switchControlChanged({ closed: item.closed }, item)"
                  v-model="item.closed"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.isForceClosed`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="
                    switchControlChanged(
                      { isForceClosed: item.isForceClosed },
                      item
                    )
                  "
                  v-model="item.isForceClosed"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.isDiscount`]="{ item }">
              <div
                v-if="item.type == 'business'"
                style="display: flex ; justify-content: center;!important"
              >
                <v-switch
                  @change="
                    switchControlChanged({ isDiscount: item.isDiscount }, item)
                  "
                  v-model="item.isDiscount"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.type`]="{ item }">
              {{ item.type | filterType }}
            </template>

            <template v-slot:[`item.discountMax`]="{ item }">
              {{
                item.type == "url"
                  ? ""
                  : item.type == "business"
                  ? item.discountMax
                  : ""
              }}
            </template>

            <template v-slot:[`item.options`]="{ item }">
              <v-btn @click="editSection(item)" small color="primary"
                >Editar</v-btn
              >
              <!-- <v-btn small color="primary" class="ml-5">Configurar</v-btn> -->
              <v-btn
                class="ml-5 white--text"
                @click="
                  itemToDelete = item;
                  modalDelete = true;
                "
                small
                color="red darken-4"
                >Eliminar</v-btn
              >
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="modalSort" max-width="500px">
      <sort
        :key="`sort-${randomKey}`"
        @cancel="modalSort = false"
        :cityKey="city['.key']"
        :section="localSection"
      >
      </sort>
    </v-dialog>

    <v-dialog persistent v-model="modalAddItem" max-width="800px">
      <add-item
        @updated="handleUpdate"
        @success="handleCreate"
        :key="randomKey"
        @cancel="modalAddItem = false"
        :selectedItem="selectedItem"
        :section="localSection"
        :city="city"
      >
      </add-item>
    </v-dialog>

    <v-dialog max-width="400" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ itemToDelete.shortName }}</span
          >?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../../../components/Lottie";
import * as animationData from "../../../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import { mapActions, mapState } from "vuex";
import addItem from "./add-item";
import sort from "./sort";

export default {
  name: "sections",
  props: ["city", "section"],
  components: {
    lottie: Lottie,
    addItem,
    sort,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      selectedItem: null,
      modalEditSection: false,
      modalSort: false,
      modalAddItem: false,
      modalDelete: false,
      itemToDelete: null,
      search: null,
      localSection: {},
      randomKey: 0,
      headers: [
        { text: "Posición", value: "position" },
        { text: "Nombre", value: "shortName" },
        { text: "Tipo", value: "type" },
        { text: "Porcentaje Descuento", value: "discountMax" },
        { text: "Activo", value: "active", align: "center", justify: "center" },
        {
          text: "Abierto",
          value: "isOpen",
          align: "center",
          justify: "center",
        },
        { text: "Comercio Activo", value: "isBusinessActive" },
        { text: "Próximamente", value: "isComingSoon" },
        { text: "Cerrado", value: "closed" },
        { text: "Forzar cerrado", value: "isForceClosed" },
        { text: "Descuento", value: "isDiscount" },
        { value: "options", align: "end", width: "250px" },
      ],
    };
  },
  async mounted() {
    // db.collection(`cities/${this.city['.key']}/sections`).doc(this.section['.key'])
    // .get()
    // .then( response => {
    //     this.localSection = response.data()
    //     this.localSection[".key"] = response.id
    //     this.loading = false
    // })

    await this.$binding(
      "localSection",
      db
        .collection(`cities/${this.city[".key"]}/sections`)
        .doc(this.section[".key"])
    );
    this.loading = false;
  },
  filters: {
    filterType(type) {
      let types = {
        url: "Url",
        business: "Comercios",
        product: "Productos",
        category: "Categoría",
      };

      return types[type] ?? "";
      // == "url"
      //                 ? "Url"
      //                 : item.type == "business"
      //                 ? "Comercio"
      //                 : "Producto"
    },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    handleCreate(data) {
      this.modalAddItem = false;
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
    },

    handleUpdate() {
      this.modalAddItem = false;
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
    },

    confirmDelete() {
      if (this.itemToDelete) {
        let target = this.localSection.target.filter(
          (item) => item.position != this.itemToDelete.position
        );
        db.collection(`cities/${this.city[".key"]}/sections`)
          .doc(this.section[".key"])
          .update({
            target: target,
          })
          .then(() => {
            if (this.itemToDelete.business) {
              db.collection("businesses")
                .doc(this.itemToDelete.business)
                .update({
                  isLobbySection: false,
                });
            }
            this.localSection.target = target;
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Item eliminado correctamente.";
            this.sortTarget();
          })
          .catch((error) => {
            console.log(error);
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    sortTarget() {
      this.localSection.target.forEach((item, index) => {
        item.position = index + 1;
      });

      db.collection(`cities/${this.city[".key"]}/sections`)
        .doc(this.section[".key"])
        .update({
          target: this.localSection.target,
        })
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    switchControlChanged(data, section) {
      if (data) {
        this.saving = true;
        db.collection(`cities/${this.city[".key"]}/sections`)
          .doc(this.section[".key"])
          .update({
            target: this.localSection.target,
          })
          .then((ref) => {
            db.collection("businesses").doc(section.business).update({
              isLobbySection: section.active,
            });
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            section.active = !section.active;
          });
      }
    },
    newItem() {
      this.randomKey = Math.random();
      this.selectedItem = null;
      this.modalAddItem = true;
    },

    editSection(section) {
      this.randomKey = Math.random();
      this.selectedItem = section;
      this.modalAddItem = true;
    },
  },
};
</script>
