<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">
          {{
            selectedItem
              ? "Editar ítem de la sección"
              : "Agregar ítem a la sección"
          }}</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-row>
                <v-col class="mt-5" cols="5" sm="5" md="5">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div
                        v-show="imgPreview"
                        @click.prevent="showFileChooser"
                        class="category-image"
                      >
                        <img :src="imgPreview" v-on="on" />
                      </div>

                      <div
                        v-on="on"
                        v-show="!imgPreview"
                        @click.prevent="showFileChooser"
                        class="img-container mt-3"
                      >
                        <i style="font-size: 30px" class="fas fa-image"></i>
                        <span class="ml-2">
                          {{
                            item.type == "url"
                              ? "Cambiar imagen"
                              : "Vista previa de la imágen"
                          }}</span
                        >
                      </div>
                    </template>
                    <span>{{
                      item.type == "url"
                        ? "Clic para agregar la imagen"
                        : "Imagen del producto o comercio seleccionado"
                    }}</span>
                  </v-tooltip>
                  <input
                    class="upload"
                    ref="input"
                    type="file"
                    name="image"
                    accept="image/*"
                    @change="imageChanged"
                  />
                </v-col>
                <v-col cols="7" sm="7" md="7">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <p>Escoge el tipo de ítem</p>
                      <v-select
                        :items="type"
                        v-model="item.type"
                        class="select-city mt-6"
                        item-text="name"
                        item-value="value"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col v-if="item.type == 'url'" cols="12" sm="12" md="12">
                      <p>Nombre del ítem</p>
                      <input
                        class="control-input"
                        type="text"
                        placeholder="Ingrese el nombre del item"
                        v-model="item.shortName"
                      />
                    </v-col>

                    <v-col v-if="item.type != 'url'" cols="12" sm="12" md="12">
                      <p>Escoge una categoría</p>
                      <v-select
                        :items="categories"
                        :loading="loading"
                        v-model="item.category"
                        class="select-city mt-6"
                        @change="getBusinesses"
                        item-text="name"
                        item-value=".key"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col v-if="item.type == 'url'" cols="12" sm="12" md="12">
              <p>Ingresa una url</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese una url"
                v-model="item.url"
              />
            </v-col>

            <v-col
              v-if="item.type == 'business' || item.type == 'product'"
              cols="12"
              sm="12"
              md="12"
            >
              <p>Escoge un comercio</p>
              <v-select
                :disabled="!item.category"
                :items="sortedArrayBusinesses"
                v-model="item.business"
                @change="getProducts"
                class="select-city mt-6"
                item-text="shortName"
                item-value=".key"
                outlined
              ></v-select>
            </v-col>

            <v-col v-if="item.type == 'product'" cols="12" sm="12" md="12">
              <p>Escoge un Producto</p>
              <v-select
                :disabled="!item.business"
                :items="sortedArrayProducts"
                v-model="item.product"
                class="select-city mt-6"
                item-text="name"
                @change="productChanged"
                item-value=".key"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="item.active"
                  label="Item de sección activo"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  v-if="selectedItem"
                  @click="updateItem()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
                <v-btn
                  v-else
                  @click="addItem()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../../../components/Lottie";
import * as animationData from "../../../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";

export default {
  name: "edit-section",
  props: ["city", "section", "selectedItem"],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      categories: [],
      businesses: [],
      business: "",
      imgPreview: "",
      imageFile: "",
      products: [],
      // product : '',
      loading: true,
      item: {
        type: "",
        shortName: "",
        active: false,
        category: "",
        deleted: false,
        product: "",
        father: "fatherId",
        business: "",
        url: "",
        cover: {},
        createdAt: "",
        createdBy: this.$store.state.user[".key"],
      },

      type: [
        { name: "Categoría", value: "category" },
        { name: "Comercio", value: "business" },
        { name: "Producto", value: "product" },
        { name: "URL", value: "url" },
      ],
    };
  },

  computed: {
    sortedArrayProducts() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      return this.products.sort(compare);
    },
    sortedArrayBusinesses() {
      function compare(a, b) {
        if (a.shortName < b.shortName) return -1;
        if (a.shortName > b.shortName) return 1;
        return 0;
      }
      return this.businesses.sort(compare);
    },
  },

  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    showFileChooser() {
      this.$refs.input.click();
    },

    imageChanged(e) {
      if (!e.target.files[0]) return;

      let ref = this;
      this.imageFile = e.target.files[0];

      if (this.imageFile && this.imageFile.type.indexOf("image/") === -1) {
        console.log("tipo de archivo no permitido");
        this.imageFile = null;
      }

      var reader = new FileReader();
      reader.onload = function (e) {
        ref.imgPreview = e.target.result;
      };

      if (this.imageFile) {
        reader.readAsDataURL(this.imageFile);
      } else {
        this.imgPreview = null;
      }
    },

    getBusinesses(category) {
      this.item.product = "";
      this.item.business = "";

      if (this.item.type == "category") {
        this.currentCategory = this.categories.find(
          (e) => e[".key"] == this.item.category
        );

        this.imgPreview = this.currentCategory.fullImage
          ? this.currentCategory.fullImage.original
          : "";
      }

      this.$binding(
        "businesses",
        db
          .collection("businesses")
          .where("ochoCategories", "array-contains", category)
      );
    },

    getProducts(business) {
      if (this.item.type == "business") {
        this.imgPreview =
          this.item.type == "business"
            ? this.businesses.find((item) => item[".key"] == business).cover
                .original
            : "";
      }
      this.$binding(
        "products",
        db
          .collection("products")
          .where("business", "array-contains", business)
          .where("deleted", "==", false)
          .where("addon", "==", false)
      );
    },

    productChanged(product) {
      this.imgPreview =
        this.products.find((item) => item[".key"] == this.item.product).images
          .length > 0
          ? this.products.find((item) => item[".key"] == product).images[0]
          : "";
    },

    validate() {
      if (!this.item.type) {
        this.snackbarText = "Debe elegir un tipo de ítem";
        this.snackbar = true;
        return false;
      } else if (this.item.type == "url" && !this.item.shortName) {
        this.snackbarText = "Debe ingresar un nombre";
        this.snackbar = true;
        return false;
      } else if (
        this.item.type == "url" &&
        (!this.item.url || (!this.imageFile && !this.item.cover.original))
      ) {
        this.snackbarText = "Debe ingresar una url y seleccionar una imagen";
        this.snackbar = true;
        return false;
      } else if (this.item.type == "business" && !this.item.business) {
        this.snackbarText = "Debe elegir un comercio";
        this.snackbar = true;
        return false;
      } else if (
        this.item.type == "product" &&
        (!this.item.business || !this.item.product)
      ) {
        this.snackbarText = "Debe elegir un comercio y un producto";
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },

    uploadImage() {
      return new Promise((resolve, reject) => {
        // console.log(this.imageFile);
        fb.app()
          .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-sections`)
          .ref()
          .child(
            `${this.city[".key"]}/${this.section[".key"]}/${this.item.id}/${this.imageFile.name}`
          )
          .put(this.imageFile)
          .then((res) => {
            resolve("success");
          })
          .catch((err) => {
            // console.log(err);
            reject(err);
          });
      });
    },

    async addItem() {
      if (this.validate()) {
        this.saving = true;

        this.item.id = `${Date.now()}-${
          Math.floor(Math.random() * (999999999999 - 99999999)) + 99999999
        }`;

        if (this.item.type == "url") {
          this.item.url = this.item.url;
          this.item.logo = { original: "", loading: "" };
        }

        if (this.item.type == "business") {
          let business = this.businesses.find(
            (item) => item[".key"] == this.item.business
          );
          this.item.logo = business.logo
            ? business.logo
            : { original: "", loading: "" };
          this.item.shortName = business.shortName ? business.shortName : "";
          this.item.cover = business.cover
            ? business.cover
            : { original: "", loading: "" };
        }

        if (this.item.type == "product") {
          let product = this.products.find(
            (item) => item[".key"] == this.item.product
          );
          this.item.cover = {
            original:
              product.images && product.images.length > 0
                ? product.images[0]
                : "",
            loading:
              product.images && product.images.length > 0
                ? product.images[0].replace("original", "loading")
                : "",
          };
          this.item.shortName = product.name ? product.name : "";
          this.item.logo = { original: "", loading: "" };
        }

        if (this.item.type == "category") {
          this.item.name = this.currentCategory.name ?? "";
          this.item.shortName = this.currentCategory.name ?? "";
          this.item.cover = this.currentCategory.fullImage ?? {};
        }

        this.item.position = this.section.target
          ? this.section.target.length + 1
          : 1;
        this.item.createdAt = new Date();
        const arrayUnion = fb.firestore.FieldValue.arrayUnion;

        db.collection(`cities/${this.city[".key"]}/sections`)
          .doc(this.section[".key"])
          .update({
            target: arrayUnion(this.item),
          })
          .then(async () => {
            if (this.imageFile) {
              await this.uploadImage();
            }
            this.saving = false;
            if (this.item.business) {
              await db.collection("businesses").doc(this.item.business).update({
                isLobbySection: this.item.active,
              });
            }
            this.$emit("success", this.item);
          })
          .catch(() => {
            this.saving = false;
            this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    async updateItem() {
      if (this.validate()) {
        this.saving = true;
        this.item.id = this.item.id
          ? this.item.id
          : `${Date.now()}-${
              Math.floor(Math.random() * (999999999999 - 99999999)) + 99999999
            }`;
        if (this.item.type == "url") {
          this.item.url = this.item.url;
        }

        if (this.item.type == "business") {
          let business = this.businesses.find(
            (item) => item[".key"] == this.item.business
          );
          this.item.logo = business.logo
            ? business.logo
            : { original: "", loading: "" };
          this.item.cover = business.cover
            ? business.cover
            : { original: "", loading: "" };
          this.item.shortName = business.shortName ? business.shortName : "";
        }

        if (this.item.type == "product") {
          //product case
          let product = this.products.find(
            (item) => item[".key"] == this.item.product
          );
          this.item.cover = {
            original:
              product.images && product.images.length > 0
                ? product.images[0]
                : "",
            loading:
              product.images && product.images.length > 0
                ? product.images[0].replace("original", "loading")
                : "",
          };
          this.item.shortName = product.name ? product.name : "";
        }

        if (this.item.type == "category") {
          this.item.name = this.currentCategory.name ?? "";
          this.item.shortName = this.currentCategory.name ?? "";
          this.item.cover = this.currentCategory.fullImage ?? {};
        }

        let target = this.section.target.map((element) => {
          if (element.position == this.item.position) return this.item;
          else return element;
        });

        db.collection(`cities/${this.city[".key"]}/sections`)
          .doc(this.section[".key"])
          .update({
            target,
          })
          .then(async (ref) => {
            if (this.imageFile) {
              await this.uploadImage();
            }
            if (this.item.business) {
              db.collection("businesses").doc(this.item.business).update({
                isLobbySection: this.item.active,
              });
            }
            this.$emit("updated", target);
          })
          .catch((error) => {
            console.log(error);
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
  },

  async mounted() {
    let categories = this.$binding(
      "categories",
      db
        .collection("categories")
        .where("deleted", "==", false)
        .where("city", "==", this.city[".key"])
    );
    await Promise.all([categories]);

    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);
      this.loading = false;
      if (this.item.type == "business") {
        this.$binding(
          "businesses",
          db
            .collection("businesses")
            .where("ochoCategories", "array-contains", this.item.category)
        );
        this.imgPreview = this.item.cover ? this.item.cover.original : "";
      } else if (this.item.type == "product") {
        this.$binding(
          "businesses",
          db
            .collection("businesses")
            .where("ochoCategories", "array-contains", this.item.category)
        );
        this.$binding(
          "products",
          db
            .collection("products")
            .where("business", "array-contains", this.item.business)
            .where("deleted", "==", false)
            .where("addon", "==", false)
        );
        this.imgPreview = this.item.cover ? this.item.cover.original : "";
      } else {
        this.imgPreview = this.item.cover ? this.item.cover.original : "";
      }
    } else {
      this.loading = false;
    }

    if (this.item && this.item.type == "category") {
      this.currentCategory = this.categories.find(
        (e) => e[".key"] == this.item.category
      );
    }
  },
};
</script>

<style scoped>
.category-image {
  max-height: 230px;
  min-height: 230px;
  max-width: 100%;
  min-width: 100%;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-image img {
  max-width: 100%;
  max-height: 230px;
  min-width: 100%;
  min-height: 230px;
  cursor: pointer;
  /* margin-top: 60px; */
  /* border: 1px solid rgba(199, 196, 196, 0.377); */
  border-radius: 10px;
  object-fit: contain;
}

.upload {
  display: none;
}

.img-container {
  max-height: 230px;
  min-height: 230px;
  max-width: 100%;
  min-width: 100%;
  cursor: pointer;
  background-color: #ad9f9f42;
  border: 1px solid #808080b3;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>