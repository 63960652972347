<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">
          {{ sectionSelected ? "Editar" : "Nueva" }} sección</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p>Nombre de la sección</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el nombre de la sección"
                v-model="section.name"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Subtítulo de la sección</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el subtítulo de la sección"
                v-model="section.subTitle"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Seleccionar categoría</p>
              <v-autocomplete
                :items="sortedCategories"
                hide-detail
                item-text="name"
                item-value=".key"
                v-model="section.idCategory"
                placeholder="Seleccione la categoría"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p>Seleccionar tamaño</p>
              <v-autocomplete
                :items="sizes"
                hide-detail
                v-model="section.size"
                placeholder="Seleccione la categoría"
                outlined
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-switch
                v-model="section.hideName"
                label="Ocultar nombre"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-switch
                v-model="section.hideBackground"
                label="Ocultar fondo"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="section.active"
                  label="Sección activa"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  v-if="sectionSelected"
                  @click="updateSection()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
                <v-btn
                  v-else
                  @click="addSection()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../../components/Lottie";
import * as animationData from "../../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";

export default {
  name: "edit-section",
  props: ["city", "sectionSelected", "sectionsLength"],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      categories: [],
      section: {
        name: "",
        number: "",
        subTitle: "",
        createdAt: "",
        idCategory: "",
        createdBy: this.$store.state.user[".key"],
        orderBy: "position",
        deleted: false,
        active: false,
      },
      sizes: [
        {
          text: "Por defecto",
          value: "default",
        },
        {
          text: "Pequeño",
          value: "small",
        },
        {
          text: "Medio",
          value: "medium",
        },
        {
          text: "Grande",
          value: "large",
        },
      ],
    };
  },

  computed: {
    sortedCategories() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.categories.sort(compare);
    },
  },

  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    updateSection() {
      if (this.section.name) {
        this.saving = true;
        let section = {
          name: this.section.name,
          active: this.section.active,
          subTitle: this.section.subTitle,
          modifiedBy: this.$store.state.user[".key"],
          idCategory: this.section.idCategory ? this.section.idCategory : "",
          modifiedAt: new Date(),
          hideBackground: this.section.hideBackground || false,
          hideName: this.section.hideName || false,
          size: this.section.size || "default",
        };

        db.collection(`cities/${this.city[".key"]}/sections`)
          .doc(this.section[".key"])
          .update(section)
          .then(() => {
            this.saving = false;
            this.$emit("success", this.section);
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    addSection() {
      if (!this.section.name) {
        this.snackbarText = "Debe ingresar el nombre de la sección.";
        this.snackbar = true;
        return;
      } else {
        this.section.createdAt = new Date();
        this.section.position = this.sectionsLength + 1;
        this.saving = true;
        db.collection(`cities/${this.city[".key"]}/sections`)
          .add(this.section)
          .then((ref) => {
            this.saving = false;
            let addedSection = this.section;
            addedSection[".key"] = ref.id;
            this.$emit("success", addedSection);
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
  },
  async mounted() {
    if (this.sectionSelected) {
      this.section = Object.assign({}, this.sectionSelected);
    }

    await this.$binding(
      "categories",
      db
        .collection("categories")
        .where("deleted", "==", false)
        .where("city", "==", this.city[".key"])
    );
  },
};
</script>


<style>
.category-color {
  -webkit-appearance: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  height: 45px !important;
  cursor: pointer;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.category-color::-webkit-color-swatch {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  padding: 0;
}
.category-color::-webkit-color-swatch-wrapper {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  padding: 0;
}

.select-city {
  border-radius: 50px;
  padding: 0;
}

.control-input {
  /* height: 55px!important; */
  border-color: rgba(117, 117, 117, 0.582);
  /* color: black; */
}

.ant-input-number {
  height: 45px !important;
  width: 80% !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.new-tag {
  background-color: white;
  padding: 10px;
}

.emoji-wysiwyg-editor {
  border: 1px solid rgba(90, 89, 89, 0.432);
  border-radius: 10px !important;
  padding: 10px;
  margin-top: 10px;
  color: black;
}

.emoji-wysiwyg-editor:focus {
  outline: none !important;
  border: 1px solid #f06f3d;
  box-shadow: 0 0 5px #f06f3d;
}

.v-text-field--outlined fieldset {
  border-radius: 10px !important;
}

.emoji-picker-icon {
  margin-top: 5px;
}
</style>