<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">
          {{ selectedLink ? "Editar" : "Nuevo" }} enlace</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p>Nombre del enlace</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el nombre del enlace"
                v-model="link.name"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Ícono</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el ícono del enlace"
                v-model="link.icon"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Url</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese la url del enlace"
                v-model="link.url"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="link.active"
                  label="Enlace activo"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  v-if="selectedLink"
                  @click="updateLink()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
                <v-btn
                  v-else
                  @click="addLink()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "../../../../components/Lottie";
import * as animationData from "../../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "add-edit-link",
  props: ["city", "selectedLink", "position"],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      link: {
        name: "",
        icon: "",
        url: "",
        createdAt: "",
        createdBy: this.$store.state.user[".key"],
        deleted: false,
        active: false,
      },
    };
  },

  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    updateLink() {
      if (this.link.name && this.link.icon && this.link.url) {
        this.saving = true;
        let link = {
          name: this.link.name,
          active: this.link.active,
          url: this.link.url,
          icon: this.link.icon,
          modifiedBy: this.$store.state.user[".key"],
          modifiedAt: new Date(),
        };

        db.collection(`cities/${this.city[".key"]}/links`)
          .doc(this.selectedLink[".key"])
          .update(link)
          .then(() => {
            this.saving = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Debe completar todos los campos";
        this.snackbar = true;
      }
    },

    addLink() {
      if (!this.link.name || !this.link.icon || !this.link.url) {
        this.snackbarText = "Debe completar todos los campos";
        this.snackbar = true;
        return;
      } else {
        this.link.createdAt = new Date();
        this.link.position = this.position;
        this.saving = true;

        db.collection(`cities/${this.city[".key"]}/links`)
          .add(this.link)
          .then(() => {
            this.saving = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
  },
  mounted() {
    if (this.selectedLink) {
      this.link = Object.assign({}, this.selectedLink);
    }
  },
};
</script>

