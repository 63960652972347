<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <!-- <v-overlay class="loading-center" :value="loading">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay> -->

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Ciudades</h1>
      </v-col>
      <!-- <v-col cols="12" sm="1" md="1" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                fab
                v-on="on"
                class="mr-1"
                dark
                :elevation="0"
                :minHeight="56"
                :loading="loading"
              >
                <v-icon x-large dark>fa-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar data a .CSV</span>
          </v-tooltip>
        </v-col> -->
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            v-if="roles.includes(user.type)"
            class="ma-2 white--text"
            @click="modalAddCity = true"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nueva ciudad
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="getHeaders"
          :items="cities"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          :sort-desc="true"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              :loading="loading"
              :disabled="!roles.includes(user.type)"
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.isRaining`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { isRaining: item.isRaining },
                  item['.key'],
                  item
                )
              "
              v-model="item.isRaining"
            ></v-switch>
          </template>

          <template v-slot:[`item.country`]="{ item }">
            {{ item.countryName }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="viewCity(item)" small color="primary">Editar</v-btn>
            <v-btn
              @click="viewSections(item)"
              small
              :loading="loading"
              color="primary"
              class="ml-3"
              >Secciones</v-btn
            >
            <v-btn @click="viewLinks(item)" small color="primary" class="ml-3"
              >Enlaces</v-btn
            >
            <v-btn
              @click="deleteCity(item)"
              small
              :loading="loading"
              class="ml-3 white--text"
              color="red darken-4"
            >
              Eliminar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- modal edit city -->
    <v-dialog v-if="citySelected" v-model="modalEditCity" max-width="600px">
      <edit-city
        @cancel="modalEditCity = false"
        @success="handleUpdate"
        :key="citySelected['.key']"
        :city="citySelected"
        :countries="countries"
      ></edit-city>
    </v-dialog>

    <!-- modal edit sections -->
    <v-dialog v-if="citySelected" v-model="modalEditSections" max-width="70%">
      <edit-sections
        @cancel="modalEditSections = false"
        :key="citySelected['.key']"
        :city="citySelected"
      ></edit-sections>
    </v-dialog>

    <!-- modal edit links -->
    <v-dialog v-if="citySelected" v-model="modalEditLinks" max-width="70%">
      <links
        @cancel="modalEditLinks = false"
        :key="citySelected['.key']"
        :city="citySelected"
      ></links>
    </v-dialog>

    <!-- modal add city -->
    <v-dialog v-model="modalAddCity" max-width="600px">
      <v-overlay class="loading-center" :value="loading">
        <lottie
          :options="defaultOptions"
          :height="300"
          :width="400"
          v-on:animCreated="handleAnimation"
        />
      </v-overlay>
      <v-card>
        <v-card-title class="grid-close">
          <span class="headline">Nueva ciudad</span>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalAddCity = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-overlay class="loading-center" :value="loading">
              <lottie
                :options="defaultOptions"
                :height="300"
                :width="400"
                v-on:animCreated="handleAnimation"
              />
            </v-overlay>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <p>Nombre de la ciudad</p>
                <input
                  class="control-input"
                  type="text"
                  placeholder="Ingrese el nombre"
                  v-model="newCity.name"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <p>Código de flota</p>
                <input
                  class="control-input"
                  type="text"
                  placeholder="Código de flota"
                  v-model="newCity.fleetTeamId"
                />
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <p>Simbolo de moneda</p>
                <input
                  class="control-input"
                  type="text"
                  placeholder="Ingrese el simbolo"
                  v-model="newCity.currency"
                />
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <p>Código de moneda</p>
                <input
                  class="control-input"
                  type="text"
                  placeholder="Ingrese el código"
                  v-model="newCity.currencyCode"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <p>Pais</p>
                <v-select
                  :items="countries"
                  v-model="newCity.countryId"
                  class="select-city"
                  item-text="name"
                  item-value=".key"
                  outlined
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <p>Saludo</p>
                <input
                  class="control-input"
                  type="text"
                  placeholder="Ingrese el saludo"
                  v-model="newCity.welcome"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <p>Hora de apertura</p>
                <input
                  v-model="newCity.opensAt"
                  type="time"
                  class="control-input"
                  required
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <p>Hora de cierre</p>
                <input
                  v-model="newCity.closesAt"
                  type="time"
                  class="control-input"
                  required
                />
              </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>

            <v-row class="pa-0 ma-0">
              <v-col cols="8" xs="8" sm="8" md="8">
                <v-row justify="start">
                  <v-switch
                    label="Ciudad activa"
                    v-model="newCity.active"
                  ></v-switch>
                </v-row>
              </v-col>

              <v-col cols="4" xs="4" sm="4" md="4">
                <v-row justify="end">
                  <v-btn
                    class="save-btn mt-3"
                    @click="saveCity"
                    color="primary"
                  >
                    Guardar
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- modal delete city -->
    <v-dialog max-width="400" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline">
          Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold"> {{ itemToDelete.name }} </span>?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import editCity from "./edit-city";
import editSections from "./sections/sections";
import links from "./links/links";
import { mapState } from "vuex";

export default {
  name: "cities",
  components: {
    lottie: Lottie,
    editCity,
    editSections,
    links,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      // loading: false,
      snackbar: false,
      modalEditBusinesses: false,
      snackbarText: "",
      itemToEdit: {},
      itemToDelete: null,
      modalDelete: false,
      citySelected: null,
      modalEditCity: false,
      cities: [],
      newCity: {
        name: "",
        welcome: "",
        currency: "",
        currencyCode: "",
        countryId: "",
        active: false,
        deleted: false,
        fleetTeamId: "",
      },
      modalAddCity: false,
      modalEditSections: false,
      modalEditLinks: false,
      countries: [],
      roles: ["sudo", "admin", "EFCM", "AC"],
      headers: [
        {
          text: "Pais",
          value: "country",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Simbolo de la moneda",
          value: "currency",
        },
        {
          text: "Código de la moneda",
          value: "currencyCode",
        },
        {
          text: "Saludo",
          value: "welcome",
        },
        {
          text: "Activar cargo por lluvia",
          value: "isRaining",
        },

        {
          text: "Activo",
          value: "active",
        },
        {
          value: "options",
          align: "end",
          width: "400px",
          roles: ["sudo", "admin", "EFCM", "AC"],
        },
      ],
    };
  },

  computed: {
    ...mapState(["user"]),
    getHeaders() {
      return this.headers.filter(
        (i) => !i.roles || (i.roles && i.roles.includes(this.user.type))
      );
    },
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },
  watch: {
    cities() {
      this.cities.forEach((city) => {
        if (city.countryId) {
          let country = this.countries.find(
            (item) => item[".key"] == city.countryId
          );
          city.countryName = country.name ? country.name : "";
        }
      });
    },
  },
  methods: {
    viewCity(item) {
      this.citySelected = item;
      this.modalEditCity = true;
    },
    viewSections(item) {
      this.citySelected = item;
      this.modalEditSections = true;
    },
    viewLinks(item) {
      this.citySelected = item;
      this.modalEditLinks = true;
    },
    saveCity() {
      if (this.newCity.opensAt && this.newCity.closesAt) {
        let opensAt = Number(this.newCity.opensAt.replaceAll(":", ""));
        let closesAt = Number(this.newCity.closesAt.replaceAll(":", ""));

        if (opensAt > closesAt) {
          this.snackbarText =
            "La hora de apertura debe ser mayor a la de cierre.";
          this.snackbar = true;
          return;
        }
      }

      if (!this.newCity.name || !this.newCity.countryId) {
        this.snackbarText = "Debe llenar todos los campos.";
        this.snackbar = true;
        return;
      } else {
        this.loading = true;
        this.newCity.createdAt = new Date();
        this.newCity.modifiedAt = new Date();
        this.newCity.createdBy = this.$store.state.user[".key"];

        db.collection("cities")
          .add(this.newCity)
          .then((ref) => {
            this.loading = false;
            this.modalAddCity = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
    handleUpdate(result) {
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
      this.modalEditCity = false;
      this.cities = this.cities.map((city) => {
        return city[".key"] == result[".key"] ? result : city;
      });
    },
    switchControlChanged(data, cityId, city) {
      if (data && cityId) {
        this.loading = true;
        db.collection("cities")
          .doc(cityId)
          .update(data)
          .then((ref) => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            if (data.active) {
              city.active = !city.active;
            }

            if (data.isRaining) {
              city.isRaining = !city.isRaining;
            }
          });
      }
    },
    confirmDelete() {
      if (this.itemToDelete) {
        db.collection("cities")
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Ciudad eliminada";
            this.cities = this.cities.filter(
              (city) => city[".key"] != this.itemToDelete[".key"]
            );
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
    deleteCity(item) {
      this.itemToDelete = item;
      this.modalDelete = true;
    },
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "ciudad");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    await db
      .collection("countries")
      .where("deleted", "==", false)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let country = item.data();
          country[".key"] = item.id;
          this.countries.push(country);
        });
      });

    await db
      .collection("cities")
      .where("deleted", "==", false)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let city = item.data();
          city[".key"] = item.id;
          this.cities.push(city);
        });
      });

    this.loading = false;
  },
};
</script>

<style>
.select-city {
  border-radius: 50px;
  padding: 0;
}
</style>
