<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Editar ciudad</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container v-if="!loading" fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <p>Nombre de la ciudad</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el nombre"
                v-model="cityToEdit.name"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Código de flota</p>
              <input
                class="control-input"
                type="text"
                placeholder="Código de flota"
                v-model="cityToEdit.fleetTeamId"
              />
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <p>Simbolo de moneda</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el simbolo"
                v-model="cityToEdit.currency"
              />
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <p>Código de moneda</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el código"
                v-model="cityToEdit.currencyCode"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Pais</p>
              <v-select
                :items="countries"
                v-model="cityToEdit.countryId"
                item-text="name"
                item-value=".key"
                hide-details
                class="select-city mt-6"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Máximo categorías</p>
              <input
                v-model.number="cityToEdit.maxCategoryAllowed"
                type="number"
                class="control-input control-input-number"
                required
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Saludo</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese el saludo"
                v-model="cityToEdit.welcome"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Hora de apertura</p>
              <input
                v-model="cityToEdit.opensAt"
                type="time"
                class="control-input"
                required
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Hora de cierre</p>
              <input
                v-model="cityToEdit.closesAt"
                type="time"
                class="control-input"
                required
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions v-if="!loading">
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="cityToEdit.active"
                  label="Ciudad activa"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  @click="updateCity()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";

export default {
  name: "edit-city",
  props: ["city", "countries"],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      cityToEdit: {
        maxCategoryAllowed: 0,
      },
      cities: [],
    };
  },
  async mounted() {
    this.cityToEdit = Object.assign({}, this.city);
    this.loading = false;
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    updateCity() {
      if (this.cityToEdit.opensAt && this.cityToEdit.closesAt) {
        let opensAt = Number(this.cityToEdit.opensAt.replaceAll(":", ""));
        let closesAt = Number(this.cityToEdit.closesAt.replaceAll(":", ""));

        if (opensAt > closesAt) {
          this.snackbarText =
            "La hora de apertura debe ser mayor a la de cierre.";
          this.snackbar = true;
          return;
        }
      }

      if (!this.cityToEdit.name || !this.cityToEdit.countryId) {
        this.snackbarText = "Debe llenar todos los campos.";
        this.snackbar = true;
        return;
      } else {
        this.saving = true;
        let city = {
          name: this.cityToEdit.name,
          welcome: this.cityToEdit.welcome || "",
          currency: this.cityToEdit.currency || "",
          currencyCode: this.cityToEdit.currencyCode || "",
          countryId: this.cityToEdit.countryId,
          active: this.cityToEdit.active,
          fleetTeamId: this.cityToEdit.fleetTeamId || "",
          opensAt: this.cityToEdit.opensAt || "",
          closesAt: this.cityToEdit.closesAt || "",
          maxCategoryAllowed: Number(this.cityToEdit.maxCategoryAllowed) || 0,
        };

        db.collection("cities")
          .doc(this.cityToEdit[".key"])
          .update(city)
          .then(() => {
            this.saving = false;
            this.$emit("success", this.cityToEdit);
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
  },
};
</script>

<style scoped>
.category-color {
  -webkit-appearance: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  height: 45px !important;
  cursor: pointer;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.category-color::-webkit-color-swatch {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  padding: 0;
}
.category-color::-webkit-color-swatch-wrapper {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  padding: 0;
}

.select-city {
  border-radius: 50px;
  padding: 0;
}

.control-input {
  /* height: 55px!important; */
  border-color: rgba(117, 117, 117, 0.582);
  /* color: black; */
}

.ant-input-number {
  height: 45px !important;
  width: 80% !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.new-tag {
  background-color: white;
  padding: 10px;
}
</style>
